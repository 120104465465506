import { UserProfileRole } from 'ui/types/user';

import { AccountMenuOption } from '../types';
import { menuOptions } from './constants';
import { AccountMenuOptionsKeys } from './types';

class MenuOptionsMap extends Map<AccountMenuOptionsKeys, AccountMenuOption> {
  constructor(options: Record<AccountMenuOptionsKeys, AccountMenuOption>) {
    super(
      Object.entries(options) as [AccountMenuOptionsKeys, AccountMenuOption][],
    );
  }

  getSettingsOptionsGroup(): Array<AccountMenuOption> {
    return [
      this.get(AccountMenuOptionsKeys.Settings),
      this.get(AccountMenuOptionsKeys.HelpCentre),
      this.get(AccountMenuOptionsKeys.SignOut),
    ].filter(Boolean) as AccountMenuOption[];
  }

  getAdminOptions(): Array<AccountMenuOption> {
    const goToMyAdminArea = this.get(AccountMenuOptionsKeys.GoToMyAdminArea)!;
    return [goToMyAdminArea];
  }

  public getOptionsGroups({
    userProfileRole,
    appsCount = 0,
    isAdmin,
  }: {
    userProfileRole: UserProfileRole;
    appsCount?: number;
    isAdmin?: boolean;
  }): Array<AccountMenuOption[]> {
    const mainGroup = [
      this.get(AccountMenuOptionsKeys.Home),
      this.get(AccountMenuOptionsKeys.Activities),
      // this.get(AccountMenuOptionsKeys.Teams),
      this.get(AccountMenuOptionsKeys.Profile),
    ].filter(Boolean) as AccountMenuOption[];
    //
    if (appsCount > 1) {
      mainGroup.splice(1, 0, this.get(AccountMenuOptionsKeys.Apps)!);
    }

    if (userProfileRole === UserProfileRole.Employee) {
      mainGroup.splice(
        mainGroup.length - 1,
        0,
        this.get(AccountMenuOptionsKeys.LogActivity)!,
      );
    }

    /*
      NOTE:omitted for now

      if (!!isAdmin) {
        mainGroup.push(...this.getAdminOptions());
      }
    */

    return [mainGroup, this.getSettingsOptionsGroup()];
  }
}
export const menuOptionsMap = new MenuOptionsMap(menuOptions);
