import { createSlice } from '@reduxjs/toolkit';

import { RedirectState } from '~/store/slices/redirect/types';
import { ESliceNames } from '~/store/types';

const initialState: RedirectState = {
  acceptInvite: {
    hasInvite: false,
    code: '',
    inviteURL: '',
    isRedirecting: false,
    externalId: undefined,
  },
  teamInvite: {
    hasInvite: false,
    code: '',
    inviteURL: '',
    activityId: '',
    activityDefinitionId: '',
  },
  createTeam: {
    isFromEventPage: false,
    activityDefinitionId: '',
  },
  activityDetails: {
    id: '',
  },
};

export const redirectSlide = createSlice({
  name: ESliceNames.REDIRECT,
  initialState,

  reducers: {
    addAcceptInviteRedirect: (state, { payload }) => {
      return { ...state, acceptInvite: payload };
    },
    removeAcceptInviteRedirect: (state) => {
      return {
        ...state,
        acceptInvite: {
          externalId: state?.acceptInvite?.externalId,
          ...initialState.acceptInvite,
        },
      };
    },
    addTeamInviteRedirect: (state, { payload }) => {
      return { ...state, teamInvite: payload };
    },
    removeTeamInviteRedirect: (state) => {
      return { ...state, teamInvite: initialState.teamInvite };
    },
    addCreateTeamRedirect: (state, { payload }) => {
      return { ...state, createTeam: payload };
    },
    removeCreateTeamRedirect: (state) => {
      return { ...state, createTeam: initialState.createTeam };
    },
    addActivityDetailsRedirect: (state, { payload }) => {
      return { ...state, activityDetails: payload };
    },
    removeActivityDetailsRedirect: (state) => {
      return { ...state, activityDetails: initialState.activityDetails };
    },
  },
});

export const { reducer: redirectSliceReducer, actions: redirectSliceActions } =
  redirectSlide;
