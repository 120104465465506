import { Box } from 'native-base';
import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { GuestLayout } from '~/components/Layouts/GuestLayout';
import { UnauthLayout } from '~/components/Layouts/UnauthLayout';
import Page403 from '~/components/Page403';
import Page404 from '~/components/Page404';
import { PAGES } from '~/constants/pages.constants';
import { FindOpportunitiesPage } from '~/pages/Authenticated/FindOpportunitiesPage';
import { ActivityViewPage } from '~/pages/Authenticated/v2/ActivityView';
import { AcceptInvite } from '~/pages/Common/AcceptInvite';
import { AccountDeleted } from '~/pages/Common/AccountDeleted';
import InvitationDeclined from '~/pages/Common/InvitationDeclined';
import { TeamInvitation } from '~/pages/Common/TeamInvitation';

const pathParams = location.pathname.split('/');
const activityId = pathParams[pathParams.length - 1];
export const CommonRoutes = (
  <React.Fragment>
    <Route path={PAGES.Page403} element={<Page403 />} />

    <Route path={PAGES.Page404} element={<Page404 />} />
    <Route path={`${PAGES.Invitation}/:code`} element={<AcceptInvite />} />
    <Route path={`${PAGES.InvitationSlug}/:code`} element={<AcceptInvite />} />

    <Route
      path={`${PAGES.TeamInvitation}/:code`}
      element={<TeamInvitation />}
    />

    <Route
      element={
        <UnauthLayout>
          <Outlet />
        </UnauthLayout>
      }
    >
      <Route path={PAGES.InvitationDeclined} element={<InvitationDeclined />} />
      <Route path={PAGES.AccountDeleted} element={<AccountDeleted />} />
    </Route>

    <Route
      element={
        <GuestLayout>
          <Outlet />
        </GuestLayout>
      }
    >
      <Route
        path={PAGES.GuestOpportunities}
        element={
          <Box
            pt={{ base: 3, sm: 8 }}
            pb={{ base: 0, md: 14 }}
            px={{ base: 0, md: 12 }}
          >
            <FindOpportunitiesPage isGuestPage />
          </Box>
        }
      />

      <Route
        path={`${PAGES.TeamActivityInvitation}`}
        element={<ActivityViewPage isGuestPage />}
      />
      <Route
        path={PAGES.GuestActivityDetails}
        element={<ActivityViewPage isGuestPage />}
      />
    </Route>

    <Route
      path={PAGES.ActivitiesDetails}
      element={
        <Navigate
          to={`${PAGES.GuestActivityDetails.replace(':id', activityId)}`}
          replace
        />
      }
    />

    <Route path="*" element={<Navigate to={PAGES.Page404} replace />} />
  </React.Fragment>
);
