import { ActivitySemanticType } from 'ui/types/activities';

import ActionApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/ActionApplicationPanel';
import EventApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/EventApplicationPanel';
import OngoingApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/OngoingApplicationPanel';
import { useActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-controller';
import { ActivityApplicationPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/types';

export const ActivityApplicationPanel: React.FC<
  ActivityApplicationPanelProps
> = (props) => {
  const { showGoBackButton, onGoBackPress, teams = [] } = props;

  const { activityType } = useActivityApplicationController();

  switch (activityType) {
    case ActivitySemanticType.Action:
      return (
        <ActionApplicationPanel
          onGoBackPress={onGoBackPress}
          showGoBackButton={showGoBackButton}
        />
      );

    case ActivitySemanticType.Ongoing:
      return (
        <OngoingApplicationPanel
          onGoBackPress={onGoBackPress}
          showGoBackButton={showGoBackButton}
        />
      );

    default:
      return (
        <EventApplicationPanel
          onGoBackPress={onGoBackPress}
          showGoBackButton={showGoBackButton}
          teams={teams}
        />
      );
  }
};

export default ActivityApplicationPanel;
