import { useMemo } from 'react';
import { DateFormatter } from 'ui/utils/formatDates';

import ActivityInfoCard from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard';
import { InfoDateTrailing } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/InfoDateTrailing';
import { EventApplicationCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/EventApplicationCard/types';
import { colors } from '~/theme/colors';

export const EventApplicationCard: React.FC<EventApplicationCardProps> = (
  props,
) => {
  const {
    activity,
    isSelected,
    isFromHome = false,
    onPress,
    showChevronLeft = false,
    showChevronRight = false,
    showCheckCircle = true,
    onNext,
    onPrevious,
  } = props;

  const cardLabels = useMemo(() => {
    const locationLabel = activity?.address?.street || '';
    const startDate = DateFormatter(activity?.startDate);
    const endDate = DateFormatter(activity?.startDate);

    const labels = [
      {
        key: 'monthDate',
        icon: 'calendar',
        text: startDate.getWeekDay(),
      },
      {
        key: 'dateTime',
        icon: 'clock',
        text: `${startDate.getHours()} - ${endDate.getHours()}`,
      },
    ];

    if (isFromHome) {
      labels.push({ key: 'from-home', icon: 'home', text: 'From home' });
    } else if (locationLabel) {
      labels.push({ key: 'address', icon: 'map-pin', text: locationLabel });
    }

    if (activity?.isOnline === true) {
      labels.push({ key: 'online', icon: 'wifi', text: 'Online' });
    }

    return labels;
  }, [activity, isFromHome]);

  return (
    <ActivityInfoCard
      activity={activity}
      onPress={onPress}
      trailingContent={
        <InfoDateTrailing
          fontColor={isSelected ? colors.white : colors.black}
          date={activity.startDate}
        />
      }
      isSelected={isSelected}
      labels={cardLabels}
      showChevronLeft={showChevronLeft}
      showChevronRight={showChevronRight}
      showCheckCircle={showCheckCircle}
      onNext={onNext}
      onPrevious={onPrevious}
    />
  );
};

export default EventApplicationCard;
