import { create } from 'zustand';

import { TeamEventState } from '~/zustand/store/TeamEvent/types';

export const INITIAL_TEAM_EVENT_STATE = {
  teamEventSize: 0,
};

export const useTeamEventStore = create<TeamEventState>()((set) => ({
  ...INITIAL_TEAM_EVENT_STATE,
  setTeamEventSize: (teamEventSize: number) => set({ teamEventSize }),
  reset: () => set(INITIAL_TEAM_EVENT_STATE),
}));
