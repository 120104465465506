import { useEffect, useMemo, useRef, useState } from 'react';

import {
  ActivityViewStateFull,
  useActivityViewStore,
} from '~/pages/Authenticated/v2/ActivityView/store';
import { useTeamActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store/teams';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';

export const useActivityInteractiveTabsController = () => {
  // -- Providers
  const { activities, activityDefinition, selectedActivity } =
    useActivityViewStore((state) => state) as ActivityViewStateFull;

  const { teams, teamInvite } = useTeamActivityViewStore((state) => state);

  // -- States
  const [tabIndex, setTabIndex] = useState(0);
  const appliedActivitiesCountRef = useRef(0);

  // -- Hooks
  const appliedActivities = useMemo(() => {
    if (!activities.length) return [];
    const result = activities.filter((a: IUserActivity) => !!a.userApplication);
    return result;
  }, [activities]);

  const teamAppliedActivities = useMemo(() => {
    if (!teams?.length) return [];
    const _activities = [];
    for (const team of teams) {
      for (const activity of activities) {
        if (activity._id === team.activity) {
          _activities.push(activity);
        }
      }
    }
    return _activities;
  }, [teams, activities]);

  useEffect(() => {
    if (appliedActivities.length != appliedActivitiesCountRef.current) {
      appliedActivitiesCountRef.current = appliedActivities.length;
      setTabIndex(1);
      return;
    }
    if (!appliedActivities.length && teams?.length) {
      setTabIndex(2);
      return;
    }
    if (teamInvite) {
      setTabIndex(3);
      return;
    }
  }, [appliedActivities, teamInvite, teams]);

  // -- Handlers
  const gotToTab = (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  // -- Render Statements
  const hasApplicationsToLog = (appliedActivities ?? []).length > 0;
  const hasActivitiesToApply = activities.length > 0;

  return {
    activities,
    selectedActivity,
    tabIndex,
    gotToTab,
    appliedActivities,
    activityDefinition,
    hasApplicationsToLog,
    hasActivitiesToApply,
    teams,
    teamInvite,
    teamAppliedActivities,
  };
};
