import {
  Avatar,
  Divider,
  Flex,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import CreatableAsyncAutoComplete from 'ui/components/CreatableAsyncAutoComplete';
import Icon from 'ui/components/Icon';
import TextInput from 'ui/components/v2/inputs/TextInput';
import { colors } from 'ui/theme/colors';

import { FirstStepProps } from '../types';
import { useFirstStepController } from './controller';

export const FirstStep = ({
  onNextStep,
  team,
  selectedEcosystem,
  refetchTeam,
  isMobile,
  inviteUrl = '',
}: FirstStepProps) => {
  const {
    control,
    loadOptions,
    isEmailValid,
    handleCopyLinkToClipboard,
    handleInvite,
    isSubmitting,
    teamMemberPictures,
  } = useFirstStepController({
    onNextStep,
    team,
    selectedEcosystem,
    refetchTeam,
    isMobile,
  });

  if (!team) {
    return null;
  }

  const totalMembers =
    team.members.length + (team?.offPlatformMembers?.length || 0);

  return (
    <>
      <Stack width="100%" paddingX={6} paddingY={4}>
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <Text
            fontSize={{ base: 'md', lg: 'xl' }}
            color={colors.singletons.darkText}
            fontWeight={500}
          >
            Your team
          </Text>
          <Pressable onPress={onNextStep}>
            <HStack alignItems="center" space={2}>
              <Text fontSize="xs" fontWeight={{ lg: 500 }}>
                View all
              </Text>
              <Icon
                icon="chevron-right"
                size={16}
                color={colors.singletons.black}
              />
            </HStack>
          </Pressable>
        </HStack>

        <HStack mt={4} space={{ base: 4, lg: 6 }}>
          <VStack space={2} width="50%">
            <Text color="gray.600" fontSize="xs" fontWeight={500}>
              Organiser
            </Text>
            <HStack alignItems="center" space={2}>
              <Avatar
                source={{ uri: team.leaderSubDocument.profilePicture }}
                height={10}
                width={10}
              >
                <Icon icon="user" size={20} color={colors.white} />
              </Avatar>
              <Text
                fontSize="sm"
                fontWeight={400}
                color={colors.singletons.darkText}
              >
                {team.leaderSubDocument.userSummary.name}
              </Text>
            </HStack>
          </VStack>

          <VStack space={2} width="50%" alignItems="flex-start">
            <Text color="gray.600" fontSize="xs" fontWeight={500}>
              Team members ({totalMembers} of {team.expectedTeamSize})
            </Text>
            {!teamMemberPictures.length ? (
              <Text color="muted.400" fontSize="sm" fontWeight={400}>
                No members joined yet.
              </Text>
            ) : (
              <Avatar.Group ml={2} max={4}>
                {teamMemberPictures.map((member, index) => (
                  <Avatar
                    key={member._id}
                    source={{ uri: member.profilePicture }}
                    height={10}
                    width={10}
                    zIndex={teamMemberPictures.length + index}
                    marginLeft={-2}
                  >
                    <Icon icon="user" size={20} color={colors.white} />
                  </Avatar>
                ))}
              </Avatar.Group>
            )}
          </VStack>
        </HStack>
      </Stack>

      <Divider />

      <Stack padding={6} space={{ base: 4, lg: 6 }}>
        <Text
          fontSize={{ base: 'sm', lg: 'md' }}
          fontWeight={400}
          color="gray.600"
        >
          Invite your team members to register. Only registered members will be
          counted in the final team size.
        </Text>
        <HStack space={3} alignItems="flex-end">
          <TextInput.Base
            value={inviteUrl}
            isDisabled={true}
            label="Activity link"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            size="51px"
          />
          <Button
            height={50}
            width={88}
            minWidth={88}
            maxWidth={88}
            variant="outline"
            leftIconName="copy"
            leftIconColor={colors.singletons.black}
            leftIconSize={16}
            onPress={() =>
              handleCopyLinkToClipboard(
                team.publicInviteTeamSummary?.inviteURL || '',
              )
            }
          >
            Copy
          </Button>
        </HStack>
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          style={{ gap: 12 }}
          alignItems={{ lg: 'flex-end' }}
        >
          <Stack width="100%">
            <CreatableAsyncAutoComplete
              name="member"
              control={control}
              placeholder="Enter your teammate's email address"
              loadOptions={loadOptions}
              label="Invite by email"
            />
          </Stack>
          <Button
            height={{ base: 46, lg: 50 }}
            width={{ base: '100%', lg: 88 }}
            minWidth={{ base: '100%', lg: 88 }}
            maxWidth={{ base: '100%', lg: 88 }}
            variant="outline"
            leftIconName="send"
            leftIconColor={colors.singletons.black}
            leftIconSize={16}
            onPress={handleInvite}
            isDisabled={isEmailValid || isSubmitting}
            isLoading={isSubmitting}
          >
            Invite
          </Button>
        </Flex>
      </Stack>
    </>
  );
};
