import { HStack, IconButton, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useActivityTeamEventCounterController } from './controller';
import { ActivityTeamEventCounterProps } from './types';

export const ActivityTeamEventCounter = ({
  teamMaxSize,
  teamMinSize,
}: ActivityTeamEventCounterProps) => {
  const { teamEventSize, handleDecrement, handleIncrement } =
    useActivityTeamEventCounterController({
      teamMaxSize,
      teamMinSize,
    });

  return (
    <HStack
      alignItems="center"
      justifyContent="flex-end"
      marginBottom={0}
      marginTop="auto"
      space={6}
      width="100%"
    >
      <IconButton
        borderWidth="1"
        borderColor={colors.gray[200]}
        borderRadius="8px"
        icon={<Icon size="14px" icon="minus" />}
        isDisabled={teamEventSize === teamMinSize}
        onPress={handleDecrement}
        _focus={{
          borderColor: colors.gray[200],
        }}
        _hover={{
          backgroundColor: colors.gray[200],
        }}
      />
      <Text fontSize="2xl">{teamEventSize}</Text>
      <IconButton
        borderWidth="1"
        borderColor={colors.gray[200]}
        borderRadius="8px"
        icon={<Icon size="14px" icon="plus" />}
        isDisabled={teamEventSize === teamMaxSize}
        onPress={handleIncrement}
        _focus={{
          borderColor: colors.gray[200],
        }}
        _hover={{
          backgroundColor: colors.gray[200],
        }}
      />
    </HStack>
  );
};
