import { create } from 'zustand';

import { TeamActivityViewState } from '~/pages/Authenticated/v2/ActivityView/store/teams/types';
import { ITeam, ITeamInviteData } from '~/types/interfaces/team';

export const useTeamActivityViewStore = create<TeamActivityViewState>()(
  (set) => ({
    teams: [],
    teamInvite: undefined,
    setTeamInvite: (teamInvite: ITeamInviteData) => {
      set((state) => ({ ...state, teamInvite }));
    },
    setTeams: (teams: ITeam[]) => {
      set((state) => ({ ...state, teams }));
    },
  }),
);
