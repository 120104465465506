import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { queryClient } from '~/config/react-query.config';
import TeamService from '~/services/resources/teams';

import { SecondStepProps, TeamMemberToDelete } from '../types';
export const useSecondStepController = ({ team }: SecondStepProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<
    TeamMemberToDelete | undefined
  >(undefined);

  const removeTeamMemberModalRef = useRef<IModalRefProps>(null);

  const openRemoveTeamMemberModal = () => {
    removeTeamMemberModalRef.current?.open();
  };

  const closeRemoveTeamMemberModal = () => {
    removeTeamMemberModalRef.current?.close();
  };

  const onDeleteTeamMember = (data: TeamMemberToDelete) => {
    setMemberToDelete(data);
    openRemoveTeamMemberModal();
  };

  const handleDeleteExternalTeamMember = async () => {
    try {
      if (!team || !memberToDelete) return;
      setIsSubmitting(true);
      await TeamService.deleteExternalTeamMember({
        teamId: team._id,
        externalMemberId: memberToDelete._id,
      });
      queryClient.invalidateQueries();
      closeRemoveTeamMemberModal();
    } catch (error) {
      toast.error(`Error on trying to remove member.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteInternalTeamMember = async () => {
    try {
      if (!team || !memberToDelete) return;
      setIsSubmitting(true);
      await TeamService.removeTeamMembers({
        team: team._id,
        members: [memberToDelete._id],
      });
      queryClient.invalidateQueries();
      closeRemoveTeamMemberModal();
    } catch (error) {
      toast.error(`Error on trying to remove member.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    removeTeamMemberModalRef,
    openRemoveTeamMemberModal,
    closeRemoveTeamMemberModal,
    onDeleteTeamMember,
    handleDeleteExternalTeamMember,
    handleDeleteInternalTeamMember,
    isSubmitting,
    memberToDelete,
  };
};
