import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

export const useTeamInfoController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const openModal = () => {
    modalRef.current?.open();
  };

  const closeModal = () => {
    modalRef.current?.close();
  };

  return {
    modalRef,
    openModal,
    closeModal,
  };
};
