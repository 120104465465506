import { useEffect } from 'react';

import { useTeamEventStore } from '~/zustand/store/TeamEvent';

import { ActivityTeamEventCounterProps } from './types';

export const useActivityTeamEventCounterController = ({
  teamMaxSize,
  teamMinSize,
}: ActivityTeamEventCounterProps) => {
  const { teamEventSize, setTeamEventSize } = useTeamEventStore();

  const handleIncrement = () => {
    if (teamEventSize < teamMaxSize) {
      setTeamEventSize(teamEventSize + 1);
    }
  };

  const handleDecrement = () => {
    if (teamEventSize > teamMinSize) {
      setTeamEventSize(teamEventSize - 1);
    }
  };

  useEffect(() => {
    setTeamEventSize(teamMinSize);
  }, [teamMinSize, setTeamEventSize]);

  return {
    teamEventSize,
    handleIncrement,
    handleDecrement,
  };
};
