import {
  Avatar,
  Divider,
  Flex,
  HStack,
  IconButton,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Tag from 'ui/components/Tags/Tag';
import Tooltip from 'ui/components/v2/Tooltip';
import { colors } from 'ui/theme/colors';

import ModalRemove from '~/components/ModalRemove';

import { SecondStepProps, TeamMemberToDelete } from '../types';
import { useSecondStepController } from './controller';

interface TeamMemberCardProps {
  profilePicture?: string;
  name?: string;
  email: string;
  _id: string;
  isInternalMember: boolean;
  onDeleteMember: (data: TeamMemberToDelete) => void;
  isMobile: boolean;
}

const TeamMemberCard = ({
  email = '',
  name = '',
  profilePicture = '',
  _id = '',
  onDeleteMember,
  isInternalMember,
  isMobile,
}: TeamMemberCardProps) => {
  return (
    <Flex
      flexDir={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      style={{ gap: 16 }}
    >
      {!isMobile ? (
        <HStack
          width={{ base: '100%', md: '55%' }}
          space={4}
          alignItems="center"
        >
          {isInternalMember ? (
            <>
              <Avatar h={10} w={10} source={{ uri: profilePicture }}>
                <Icon icon="user" size={20} color={colors.white} />
              </Avatar>
              <Text
                fontSize={{ base: 'sm', lg: 'md' }}
                color={colors.singletons.darkText}
                fontWeight={400}
              >
                {name}
              </Text>
            </>
          ) : null}
        </HStack>
      ) : null}
      <HStack
        width={{ base: '100%', md: '45%' }}
        space={{ base: 2, lg: 4 }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Tooltip label={email}>
          <HStack space={2} alignItems="center">
            <Icon icon="mail" size={20} color={colors.muted[400]} />
            <Text
              fontSize={{ base: 'sm', lg: 'md' }}
              fontWeight={400}
              color={colors.singletons.darkText}
              w={'100%'}
              numberOfLines={1}
            >
              {email}
            </Text>
          </HStack>
        </Tooltip>
        <IconButton
          icon={<Icon icon="x-circle" size={18} color={colors.error[600]} />}
          onPress={() =>
            onDeleteMember({
              _id: _id,
              isInternalMember,
            })
          }
        />
      </HStack>
    </Flex>
  );
};

export const SecondStep = ({
  onBackStep,
  team,
  refetchTeam,
  isMobile,
}: SecondStepProps) => {
  const {
    removeTeamMemberModalRef,
    closeRemoveTeamMemberModal,
    isSubmitting,
    memberToDelete,
    onDeleteTeamMember,
    handleDeleteInternalTeamMember,
    handleDeleteExternalTeamMember,
  } = useSecondStepController({ team, refetchTeam, onBackStep, isMobile });

  if (!team) {
    return null;
  }

  const totalMembers =
    team.members.length + (team?.offPlatformMembers?.length || 0);

  return (
    <>
      <ModalRemove
        modalRef={removeTeamMemberModalRef}
        headerText="Remove team member"
        handleCloseModal={closeRemoveTeamMemberModal}
        handleDelete={
          memberToDelete?.isInternalMember
            ? handleDeleteInternalTeamMember
            : handleDeleteExternalTeamMember
        }
        rightBtnLoading={isSubmitting}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this member of your team?
          </Text>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            The member will no longer be able to participate in team events of
            this team.
          </Text>
        </Stack>
      </ModalRemove>
      <Stack width="100%" paddingX={6} paddingY={4} space={3}>
        <Pressable onPress={onBackStep}>
          <HStack alignItems="center" space={2}>
            <Icon
              icon="chevron-left"
              size={16}
              color={colors.singletons.black}
            />
            <Text fontSize="xs" fontWeight={{ lg: 500 }}>
              Back
            </Text>
          </HStack>
        </Pressable>
        <HStack justifyContent="space-between" alignItems="center">
          <Text
            fontSize={{ base: 'md', lg: 'xl' }}
            color={colors.singletons.darkText}
            fontWeight={500}
          >
            Your team
          </Text>
          <HStack alignItems="center" space={2}>
            <Icon
              icon="users"
              size={isMobile ? 18 : 24}
              color={colors.gray[500]}
            />
            <Tag
              tagBgColor="none"
              tagProps={{
                borderWidth: 1,
                borderColor: colors.gray[200],
              }}
              textTransform="none"
              text={
                <Text
                  fontSize={{ base: 'xs', lg: 'sm' }}
                  fontWeight={500}
                  color="gray.500"
                >
                  {totalMembers} of {team.expectedTeamSize} members
                </Text>
              }
            />
          </HStack>
        </HStack>
      </Stack>
      <Divider />
      {totalMembers > 0 ? (
        <Stack padding={6} space={{ base: 4, lg: 6 }}>
          {/* internal members */}
          {team.membersSubDocument.map((member) => (
            <TeamMemberCard
              email={member.userSummary.email}
              key={member._id}
              name={member.userSummary.name}
              profilePicture={member?.profilePicture}
              _id={member._id}
              onDeleteMember={onDeleteTeamMember}
              isInternalMember={true}
              isMobile={isMobile}
            />
          ))}

          {/* external members */}
          {team.offPlatformMembers?.map((member) => (
            <TeamMemberCard
              email={member.email}
              key={member._id}
              name={member.name}
              onDeleteMember={onDeleteTeamMember}
              isInternalMember={false}
              _id={member._id}
              isMobile={isMobile}
            />
          ))}
        </Stack>
      ) : (
        <VStack h={400} alignItems="center" justifyContent="center">
          <Illustration name={ILLUSTRATIONS_NAME.LOADING_WAITING} size={200} />
          <Text>No members joined yet.</Text>
        </VStack>
      )}
    </>
  );
};
