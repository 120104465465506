import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { StatusCodes } from 'http-status-codes';
import { useClipboard } from 'native-base';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { queryClient } from '~/config/react-query.config';
import TeamService from '~/services/resources/teams';
import { UserProfileService } from '~/services/resources/user-profile';
import { IUserProfile } from '~/types/interfaces/user';
import Yup from '~/utils/validations/yup';

import { FirstStepProps, FormData } from '../types';

const schema = Yup.object({
  member: Yup.object({
    label: Yup.string().email('Please enter a valid email address').required(),
    value: Yup.string(),
    __isNew__: Yup.boolean(),
  }),
});

export const useFirstStepController = ({
  team,
  selectedEcosystem,
}: FirstStepProps) => {
  const { onCopy } = useClipboard();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      member: {
        label: '',
        value: '',
        __isNew__: false,
      },
    },
  });

  const handleCopyLinkToClipboard = (link: string) => {
    onCopy(link);
    toast.success('Link copied to clipboard.');
  };

  const handleInvite = handleSubmit(async (data) => {
    try {
      if (!team) return;

      const members = [];
      const newMembers = [];

      if (data.member.__isNew__) {
        newMembers.push(data.member.label || '');
      } else {
        members.push(data.member.value || '');
      }

      const payload = {
        team: team._id,
        members,
        newMembers,
      };

      await TeamService.addTeamMembers(payload);
      queryClient.invalidateQueries();
      reset();
      toast.success('Invite sent with success');
    } catch (error: any) {
      const message = error?.response?.data?.message;
      toast.error(message || 'Error on trying to invite member');
    }
  });

  const loadMembersAutocomplete = useCallback(
    async (text: string) => {
      try {
        const { data, status } =
          await UserProfileService.userProfileAutoCompleteSearch(
            text,
            selectedEcosystem?._id as string,
          );
        if (status === StatusCodes.OK) {
          const options = data?.map(
            (memberAutoComplete: Partial<IUserProfile>) => {
              return {
                value: memberAutoComplete._id,
                label: memberAutoComplete?.userSummary?.email,
                email: memberAutoComplete?.userSummary?.email,
              };
            },
          );

          return options;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedEcosystem],
  );

  const getAsyncOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      resolve(loadMembersAutocomplete(inputValue));
    });
  };

  const loadOptions = debounce(getAsyncOptions, 1000);

  const isEmailValid = !isDirty || !isValid || Object.keys(errors).length > 0;

  const teamMemberPictures = useMemo(() => {
    if (!team) return [];
    const _members = [];
    for (const memberSubDocument of team.membersSubDocument) {
      _members.push({
        _id: memberSubDocument._id,
        profilePicture: memberSubDocument?.profilePicture,
      });
    }

    for (const offPlatform of team?.offPlatformMembers || []) {
      _members.push({
        _id: offPlatform?._id,
        profilePicture: '',
      });
    }

    return _members;
  }, [team]);

  return {
    control,
    isEmailValid,
    handleCopyLinkToClipboard,
    handleInvite,
    isSubmitting,
    loadOptions,
    teamMemberPictures,
  };
};
