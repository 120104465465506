import { ActivitySemanticType } from 'ui/types/activities';

import {
  ApplicationActionStyle,
  ApplicationHeaderStyle,
} from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/types';
import { colors } from '~/theme/colors';
import { AvailabilityMessageStyle } from '~/utils/activityAvailability/types';

export const ApplicationActionStyleMapByType = new Map<
  ActivitySemanticType,
  ApplicationActionStyle
>([
  [
    ActivitySemanticType.Event,
    {
      actionLabel: 'Register for this event',
    },
  ],
  [
    ActivitySemanticType.TeamEvent,
    {
      actionLabel: 'Book team',
    },
  ],
  [
    ActivitySemanticType.Action,
    {
      actionLabel: 'Join this action',
    },
  ],
  [
    ActivitySemanticType.Ongoing,
    {
      actionLabel: 'Apply for this opportunity',
    },
  ],
]);

export const ApplicationHeaderStyleMap = new Map<
  AvailabilityMessageStyle,
  ApplicationHeaderStyle
>([
  [
    AvailabilityMessageStyle.SUCCESS,
    {
      fontColor: colors.success[900],
      backgroundColor: colors.success[200],
    },
  ],
  [
    AvailabilityMessageStyle.WARNING,
    {
      fontColor: colors.warning[900],
      backgroundColor: colors.warning[200],
    },
  ],
  [
    AvailabilityMessageStyle.ERROR,
    {
      fontColor: colors.gray[900],
      backgroundColor: colors.gray[200],
    },
  ],
]);
