import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { RootPortal } from 'ui/components/v2/RootPortal';

import { TeamEventModal } from '~/components/TeamEventModal';
import { GenericConfirmationPage } from '~/pages/Common/GenericConfirmationPage';

import { useOpportunityApplicationConfirmationController } from './controller';

export const OpportunityApplicationConfirmation = () => {
  const {
    teamId,
    handleGoBackHome,
    handleGoToMyActivities,
    selectedEcosystem,
    teamEventModalRef,
  } = useOpportunityApplicationConfirmationController();

  return (
    <>
      {teamId ? (
        <RootPortal>
          <TeamEventModal
            teamEventModalRef={teamEventModalRef}
            teamId={teamId}
            selectedEcosystem={selectedEcosystem}
          />
        </RootPortal>
      ) : null}
      <GenericConfirmationPage
        illustrationName={ILLUSTRATIONS_NAME.CONFIRMATION_GREAT}
        onPressPrimaryButton={handleGoBackHome}
        onPressSecondaryButton={handleGoToMyActivities}
        title={'Thank you for your application!'}
        description={
          'The host organisation will review your application and respond as soon as possible.'
        }
      />
    </>
  );
};
