import { HStack, Stack, Text, Tooltip } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import { colors } from 'ui/theme/colors';

import ModalRemove from '~/components/ModalRemove';

import { useTeamInfoFooterController } from './footerController';
import { TeamInfoFooterProps } from './types';

export const TeamInfoFooter = (props: TeamInfoFooterProps) => {
  const {
    logAvailability,
    handleLogAction,
    showLogActivity = false,
    team,
  } = props;

  const {
    options,
    leaveTeamModalRef,
    cancelApplicationModalRef,
    closeCancelApplicationModal,
    closeLeaveTeamModal,
    handleCancelApplication,
    handleLeaveTeam,
    isSubmitting,
  } = useTeamInfoFooterController(props);

  return (
    <>
      <ModalRemove
        modalRef={cancelApplicationModalRef}
        handleDelete={() => handleCancelApplication(team._id)}
        handleCloseModal={closeCancelApplicationModal}
        headerText="Cancel application"
        rightBtnLoading={isSubmitting}
        rightBtnText="Yes, cancel"
      >
        <Text color={'gray.500'} fontSize="sm" mt={2}>
          Do you want to cancel team application?
        </Text>
      </ModalRemove>
      <ModalRemove
        modalRef={leaveTeamModalRef}
        handleDelete={() => handleLeaveTeam(team._id)}
        handleCloseModal={closeLeaveTeamModal}
        headerText="Leave team"
        rightBtnLoading={isSubmitting}
        rightBtnText="Yes, leave"
      >
        <Text color={'gray.500'} fontSize="sm" mt={2}>
          Do you want to leave this team?
        </Text>
      </ModalRemove>

      <HStack
        mt={2}
        space={3}
        alignItems="center"
        width="100%"
        flexDir={showLogActivity ? 'row' : 'row-reverse'}
      >
        {showLogActivity && (
          <Tooltip
            label={logAvailability?.message || ''}
            display={!logAvailability?.message ? 'none' : 'unset'}
          >
            <Button
              onPress={handleLogAction}
              isDisabled={!logAvailability?.isAvailable}
              bg={'singletons.black'}
              h={'2.5rem'}
              color={'white'}
              width="87%"
              leftIconName="plus-circle"
              leftIconColor={colors.white}
              leftIconSize={16}
              _text={{ fontWeight: 500 }}
            >
              Log activity
            </Button>
          </Tooltip>
        )}
        <PopOverOptions
          customButtomStyles={{ padding: 0 }}
          customButton={
            <Stack
              borderRadius="50%"
              padding={3}
              backgroundColor={colors.gray[200]}
            >
              <Icon
                icon="more-horizontal"
                size={16}
                color={colors.singletons.black}
              />
            </Stack>
          }
          options={options}
        />
      </HStack>
    </>
  );
};
