import { Pressable, VStack } from 'native-base';

import EventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/EventApplicationCard';
import { TeamEventApplicationCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/TeamEventApplicationCard/types';
import { colors } from '~/theme/colors';

export const TeamEventApplicationCard: React.FC<
  TeamEventApplicationCardProps
> = (props) => {
  const { activity, isSelected, onPress } = props;

  return (
    <Pressable onPress={() => onPress?.(activity._id)}>
      <VStack
        w="full"
        borderWidth={1}
        borderColor={colors.gray[200]}
        borderRadius={16}
        marginBottom={3}
        backgroundColor={isSelected ? colors.gray[600] : colors.gray[50]}
      >
        <EventApplicationCard {...props} />
      </VStack>
    </Pressable>
  );
};

export default TeamEventApplicationCard;
