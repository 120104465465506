import { AxiosResponse } from 'axios';

import { api } from '~/services/api';
import { IQueryParams } from '~/services/types';
import { DeleteExternalTeamMember, ICreateTeamDTO } from '~/types/dtos';
import {
  ITeam,
  ITeamInviteData,
  ITeamPopulateEcosystem,
} from '~/types/interfaces/team';

export default class TeamService {
  static URL = '/team';

  static async me(
    userProfile: string,
    params: any,
  ): Promise<AxiosResponse<{ data: ITeam[]; count: number }>> {
    return await api.get(`${this.URL}/me/${userProfile}`, { params });
  }

  static async createTeam(teamData: ICreateTeamDTO) {
    return await api.post(this.URL, teamData);
  }

  static async updateTeam(teamId: string, teamData: Partial<ICreateTeamDTO>) {
    return await api.put(`${this.URL}/${teamId}`, teamData);
  }

  static async deleteTeam(teamId: string) {
    return await api.delete(`${this.URL}/${teamId}`);
  }

  static async addTeamMembers(
    membersData: Omit<ICreateTeamDTO, 'ecosystem' | 'leader' | 'name'>,
  ) {
    return await api.post(`${this.URL}/add-members`, membersData);
  }

  static async removeTeamMembers(membersData: {
    team: string;
    members: string[];
  }) {
    return await api.post(`${this.URL}/remove-members`, membersData);
  }

  static async leaveTeam(teamId: string, userProfileId: string) {
    return await api.post(`${this.URL}/leave`, {
      team: teamId,
      member: userProfileId,
    });
  }

  static async getUserTeams(
    userProfileId: string,
  ): Promise<AxiosResponse<ITeam[]>> {
    return (
      await api.get<AxiosResponse<ITeam[]>>(`${this.URL}/me/${userProfileId}`)
    ).data;
  }

  static async findAll(
    params?: IQueryParams,
  ): Promise<AxiosResponse<ITeamPopulateEcosystem[]>> {
    return (
      await api.get<AxiosResponse<ITeamPopulateEcosystem[]>>(this.URL, {
        params,
      })
    ).data;
  }

  static async getInvitationDataByCode(
    code: string,
  ): Promise<AxiosResponse<ITeamInviteData[]>> {
    return (await api.get(`${this.URL}/code/${code}`)).data;
  }

  static async acceptInvitation(code: string) {
    return await api.post(`${this.URL}/public-invitation/accept/${code}`);
  }

  static async rejectInvitation(code: string) {
    return await api.post(`${this.URL}/public/reject-invitation/${code}`);
  }

  static async deleteExternalTeamMember(data: DeleteExternalTeamMember) {
    return await api.post(`${this.URL}/remove-external-member`, data);
  }

  static async cancelApplication(teamId: string) {
    return await api.post(`${this.URL}/${teamId}/cancel-application`);
  }

  /* public invitation */
  static async reloadPublicInvitation(teamId: string) {
    return await api.post(`${this.URL}/${teamId}/public-invitation`);
  }

  static async acceptPublicInvitation(code: string) {
    return await api.post(`${this.URL}/public-invitation/accept/${code}`);
  }
}
