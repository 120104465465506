import { useEffect, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';

export const useOpportunityApplicationConfirmationController = () => {
  const { goToRoute, location } = useRouter();

  const { teamId } = location?.state;
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const teamEventModalRef = useRef<IModalRefProps | null>(null);

  const handleGoBackHome = () => {
    if (location?.state?.appId) {
      goToRoute(PAGES.InApp.replace(':appId', location.state.appId));
      return;
    }
    goToRoute(PAGES.Home);
  };

  const handleGoToMyActivities = () => {
    goToRoute(PAGES.Activities, { replace: true });
  };

  useEffect(() => {
    if (teamId) {
      teamEventModalRef.current?.open();
    }
  }, [teamId]);

  return {
    handleGoBackHome,
    handleGoToMyActivities,
    teamId,
    selectedEcosystem,
    teamEventModalRef,
  };
};
