import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { colors } from 'ui/theme/colors';

import { queryClient } from '~/config/react-query.config';
import { useAppSelector } from '~/hooks/useAppSelector';
import { TeamInfoFooterProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/TeamInfo/types';
import TeamService from '~/services/resources/teams';

export const useTeamInfoFooterController = (props: TeamInfoFooterProps) => {
  const {
    canCancelApplication = false,
    canLeaveTeam = false,
    resetTeam,
  } = props;

  const { selectedUserProfile } = useAppSelector((state) => state.auth);

  // refs
  const cancelApplicationModalRef = useRef<IModalRefProps>(null);
  const leaveTeamModalRef = useRef<IModalRefProps>(null);

  // states
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openCancelApplicationModal = () => {
    cancelApplicationModalRef.current?.open();
  };

  const openLeaveTeamModal = () => {
    leaveTeamModalRef.current?.open();
  };

  const closeCancelApplicationModal = () => {
    cancelApplicationModalRef.current?.close();
  };

  const closeLeaveTeamModal = () => {
    leaveTeamModalRef.current?.close();
  };

  const options = [];

  if (canCancelApplication) {
    options.push({
      title: 'Cancel application',
      icon: { icon: 'x-circle', size: 16, color: colors.error[600] },
      action: () => openCancelApplicationModal?.(),
    });
  }

  if (canLeaveTeam) {
    options.push({
      title: 'Leave team',
      icon: { icon: 'log-out', size: 16, color: colors.error[600] },
      action: () => openLeaveTeamModal?.(),
    });
  }

  const handleCancelApplication = async (teamId: string) => {
    try {
      setIsSubmitting(true);
      await TeamService.cancelApplication(teamId);
      toast.success('Application canceled');
      queryClient.invalidateQueries();
      closeCancelApplicationModal();
      resetTeam?.();
    } catch (error) {
      toast.error('Error canceling application');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLeaveTeam = async (teamId: string) => {
    try {
      if (!selectedUserProfile) return;
      setIsSubmitting(true);
      await TeamService.leaveTeam(teamId, selectedUserProfile._id);
      toast.success('Team left');
      queryClient.invalidateQueries();
      resetTeam?.();
      closeLeaveTeamModal();
    } catch (error) {
      toast.error('Error leaving team');
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    cancelApplicationModalRef,
    leaveTeamModalRef,
    closeCancelApplicationModal,
    closeLeaveTeamModal,
    options,
    handleCancelApplication,
    handleLeaveTeam,
    isSubmitting,
  };
};
