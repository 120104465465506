import { useEffect } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { redirectSliceActions } from '~/store/slices/redirect';

export const useSplashScreenController = () => {
  const { replaceRoute, goToRoute } = useRouter();
  const dispatch = useAppDispatch();

  const { user, redirectToSwitchProfile } = useAppSelector(({ auth }) => auth);
  const { acceptInvite, teamInvite, activityDetails } = useAppSelector(
    ({ redirect }) => redirect,
  );

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  useEffect(() => {
    if (activityDetails.id) {
      const route = PAGES.ActivitiesDetails.replace(':id', activityDetails.id);
      goToRoute(route);
      dispatch(redirectSliceActions.removeActivityDetailsRedirect());
    }

    if (acceptInvite.hasInvite) {
      replaceRoute(`${PAGES.Invitation}/${acceptInvite.code}`);
      return;
    }

    if (teamInvite.hasInvite) {
      const route = PAGES.TeamActivityInvitation.replace(
        ':code',
        teamInvite.code,
      ).replace(':id', teamInvite.activityDefinitionId || '');

      replaceRoute(route);
      dispatch(redirectSliceActions.removeTeamInviteRedirect());
      return;
    }

    if (selectedEcosystem && !acceptInvite.hasInvite && !teamInvite.hasInvite) {
      replaceRoute(PAGES.Home);
      return;
    }

    const userProfiles = (user?.userProfiles || [])?.length || 0;
    if (!!selectedEcosystem && !!redirectToSwitchProfile && userProfiles > 1) {
      replaceRoute(PAGES.SwitchPersona);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEcosystem, acceptInvite, teamInvite, user]);

  return { selectedEcosystem };
};
