import { useBreakpointValue } from 'native-base';
import { useState } from 'react';
import { Team } from 'ui/types/teams';

import { useQuery } from '~/hooks/useQuery';

import { TeamEventModalProps } from './types';

export const useTeamEventModalController = ({
  teamId,
  teamEventModalRef,
}: TeamEventModalProps) => {
  const [step, setStep] = useState(0);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    data: team,
    isLoading: isLoadingTeam,
    refetch: refetchTeam,
  } = useQuery<Team>(`team/${teamId}`, {
    queryOptions: { enabled: !!teamId },
  });

  const { data: teamInvite, isLoading: isLoadingTeamInvite } = useQuery(
    `team/${team?._id}/public-invite`,
    {
      queryOptions: { enabled: !!team?._id },
    },
  );

  const closeTeamEventModal = () => {
    teamEventModalRef?.current?.close();
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  return {
    closeTeamEventModal,
    handleNextStep,
    handlePreviousStep,
    isLoadingTeam,
    isLoadingTeamInvite,
    team,
    step,
    teamEventModalRef,
    refetchTeam,
    isMobile,
    teamInvite,
  };
};
