import { Box, HStack, IconButton, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import Modal from 'ui/components/Modals/Modal';
import { colors } from 'ui/theme/colors';

import { useTeamInfoController } from './controller';

export const TeamInfoHeader = () => {
  const { modalRef, openModal } = useTeamInfoController();

  return (
    <>
      <Modal
        modalContentW={{ lg: '700' }}
        modalContentMaxW={{ lg: '700' }}
        headerText="Participating in team events"
        headerFontSize={{ base: 'md', lg: '2xl' }}
        ref={modalRef}
      >
        <>
          <Stack paddingY={{ base: 2, lg: 6 }}>
            <Text fontSize={{ lg: 'md' }} fontWeight={400} color="gray.500">
              Every time you book a team event, you&apos;ll need to register a
              team. While you can join multiple dates for the same event, you
              can only participate with one team per day.
            </Text>
          </Stack>
          <Box padding={{ lg: 4 }} />
        </>
      </Modal>
      <HStack mt={4} width="100%" alignItems="center" space={2}>
        <Icon icon="users" size={20} color={colors.gray[400]} />
        <Text fontWeight={500} fontSize="md" color="gray.600">
          Teams
        </Text>
        <IconButton
          variant="unstyled"
          minW="20px"
          maxW="20px"
          maxH="20px"
          onPress={openModal}
          _icon={{ marginRight: 1 }}
          icon={<Icon icon="help-circle" size={18} color={colors.gray[900]} />}
        />
      </HStack>
    </>
  );
};
