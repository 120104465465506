import { VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import ActivityTeamSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch';
import ActivitySelectorCardBase from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase';
import { useActivityTeamSelectorController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/controller';
import TeamSelectorNavButton from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/TeamSelectorNavButton';
import { ActivityTeamSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/types';
import { ActivityTeamEventCounter } from '~/pages/Authenticated/v2/ActivityView/components/ActivityTeamEventCounter';

export const ActivityTeamSelector = (props: ActivityTeamSelectorProps) => {
  const { selectedTeam, teamsAvailable } = props;

  const {
    handleTeamSelect,
    isOpen,
    onClose,
    onOpen,
    handleCreateNewTeam,
    teamsSizeStr,
    teamMinSize,
    teamMaxSize,
  } = useActivityTeamSelectorController(props);

  return (
    <>
      <ActivitySelectorCardBase
        cardName="team"
        title={selectedTeam?.name || ''}
        titlePlaceHolder="Team size"
        tagLabel={teamsSizeStr}
        trailingContent={
          <Icon icon="users" size={'24px'} color={colors.black} />
        }
        showPopover={isOpen}
        closePopover={onClose}
        openPopover={onOpen}
        customNavButton={
          <ActivityTeamEventCounter
            teamMaxSize={teamMaxSize || 0}
            teamMinSize={teamMinSize || 0}
          />
        }
        popoverContent={
          <VStack w={'400px'} maxH="382px" minH={'240px'} space={3} pb={2}>
            <ActivityTeamSearch
              key={selectedTeam?.name}
              teams={teamsAvailable}
              selectedTeam={selectedTeam}
              onTeamChange={handleTeamSelect}
            />

            <TeamSelectorNavButton onPress={handleCreateNewTeam} />
          </VStack>
        }
      />
    </>
  );
};

export default ActivityTeamSelector;
