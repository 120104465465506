import { api } from '~/services/api';
import { IActivity } from '~/types/interfaces/activity';

export interface ApplyTeamEventToActivityDTO {
  activityId: string;
  expectedTeamSize: number;
  isLeaderMember: boolean;
  appId: string;
  leader: string;
}

export class ActivitiesService {
  static URL = '/activity';
  static PUBLIC_URL = '/activity-public';

  static async applyToActivity(
    id: string,
    userProfileId: string,
    appId: string,
  ) {
    return await api.post(
      `${this.URL}/apply/${id}/user-profile/${userProfileId}/app/${appId}`,
    );
  }

  static async applyTeamToActivity(
    activityTeamEventId: string,
    teamId: string,
    appId: string,
  ) {
    return await api.post(
      `${this.URL}/apply/team-events/${activityTeamEventId}/team/${teamId}/app/${appId}`,
    );
  }

  static async applyTeamEventToActivity(data: ApplyTeamEventToActivityDTO) {
    return await api.post(`${this.URL}/apply/team-events`, data);
  }
  //
  // static async confirmAttendanceToActivity(activityId: string, userId: string) {
  //   return await api.post(
  //     `${this.URL}/confirm/${activityId}/attendance/${userId}`,
  //   );
  // }
  //
  static async cancelAttendanceToActivity(activityId: string, userId: string) {
    return await api.post(
      `${this.URL}/cancel/${activityId}/attendance/${userId}`,
    );
  }

  static async search(params: any) {
    return await api.get(`${this.PUBLIC_URL}/search`, {
      params: { ...params },
    });
  }

  static async getAll(params: any) {
    return await api.get(`${this.URL}`, {
      params: { ...params },
    });
  }

  static async getAllPublic(params: any) {
    return await api.get(`${this.PUBLIC_URL}`, {
      params: { ...params },
    });
  }
  static async findById(activityId: string): Promise<IActivity> {
    try {
      const { data } = await api.get(`${this.URL}/${activityId}`);
      return data;
    } catch (err) {
      throw err;
    }
  }

  static async confirmAttendanceToActivity(activityId: string, userId: string) {
    return await api.post(
      `${this.URL}/confirm/${activityId}/attendance/${userId}`,
    );
  }
}
