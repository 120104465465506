import SwipeableViews from 'react-swipeable-views';
import Loading from 'ui/components/Loading';
import Modal from 'ui/components/Modals/Modal';

import { colors } from '../../theme/colors';
import { useTeamEventModalController } from './controller';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { TeamEventModalProps } from './types';

export const TeamEventModal = ({
  teamId,
  selectedEcosystem,
  teamEventModalRef,
}: TeamEventModalProps) => {
  const {
    closeTeamEventModal,
    step,
    handleNextStep,
    handlePreviousStep,
    isLoadingTeam,
    isLoadingTeamInvite,
    team,
    refetchTeam,
    isMobile,
    teamInvite,
  } = useTeamEventModalController({ teamId, teamEventModalRef });

  return (
    <Modal
      ref={teamEventModalRef}
      headerFontSize={{ base: 'md', md: '2xl' }}
      headerText="Tell us who’s joining"
      rightBtnText="Done"
      rightBtnAction={closeTeamEventModal}
      rightBtnProps={{ width: '100%' }}
      leftBtnText="Skip this step"
      leftBtnAction={closeTeamEventModal}
      bodyPadding="0"
      modalFooterBgColor={{ base: 'none', lg: colors.gray[100] }}
      modalContentMaxW={{ base: '100vw', lg: '669' }}
      modalContentMaxH={{ base: '100vh', lg: 'auto' }}
      modalContentW={{ base: '100vw', lg: '669' }}
      modalContentH={{ base: '100vh', lg: 'auto' }}
      modalFooterPadding={{ base: 4, lg: 6 }}
    >
      {isLoadingTeam || isLoadingTeamInvite ? (
        <Loading containerHeight="300px" />
      ) : (
        <SwipeableViews index={step}>
          <FirstStep
            onNextStep={handleNextStep}
            team={team}
            selectedEcosystem={selectedEcosystem}
            refetchTeam={refetchTeam}
            isMobile={isMobile}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            inviteUrl={teamInvite?.inviteURL}
          />
          <SecondStep
            onBackStep={handlePreviousStep}
            team={team}
            refetchTeam={refetchTeam}
            isMobile={isMobile}
          />
        </SwipeableViews>
      )}
    </Modal>
  );
};
