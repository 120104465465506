import { add as dateAdd } from 'date-fns';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { ActivityCategory } from 'ui/types/activities';
import { createStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn as useStore } from 'zustand/traditional';

import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';
import { AttendanceType } from '~/types/interfaces/activity';
import {
  SearchCollectionConfig,
  SearchCollectionSlug,
} from '~/types/interfaces/activity-search/types';

import { SearchFilters, SearchProps, SearchState } from './types';
export * from './types';

const today = new Date();
const defaultEndDate = dateAdd(today, { months: 6 });

export const DEFAULT_SEARCH_FILTERS = {
  searchText: '',
  causes: [],
  activityTypes: [],
  distanceRadius: 100,
  teamsMinSize: 0,
  teamsMaxSize: 25,
  startDate: today,
  endDate: defaultEndDate,
  relevance: SearchRelevanceSortBy.Newest,
  attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
};

export const SEARCH_INITIAL_STATE: SearchProps = {
  causes: [],
  isFilterModalOpen: false,
  isCollectionChanging: false,
  selectedCollection: null,
  appliedFilters: DEFAULT_SEARCH_FILTERS,
  filtersAppliedAt: -1,
  suggestions: {
    candidateCollections: [],
    processedCollections: [],
  },
};

type SearchStore = ReturnType<typeof createSearchStore>;

export const createSearchStore = (initProps?: Partial<SearchProps>) => {
  const DEFAULT_PROPS: SearchProps = {
    ...SEARCH_INITIAL_STATE,
    ...initProps,
  };

  return createStore<SearchState>()((set) => ({
    ...DEFAULT_PROPS,
    setSuggestions: (collectionSlugs: SearchCollectionSlug[]) =>
      set((state: SearchProps) => ({
        ...state,
        suggestions: {
          processedCollections: [],
          candidateCollections: collectionSlugs,
        },
      })),
    setProcessedCollection: (collectionSlug: SearchCollectionSlug) =>
      set((state: SearchProps) => {
        const processedCollections = new Set(
          state.suggestions.processedCollections,
        );
        processedCollections.add(collectionSlug); // single slug
        return {
          ...state,
          suggestions: {
            ...state.suggestions,
            processedCollections: Array.from(processedCollections),
          },
        };
      }),
    applyFilters: (newFilters: Partial<SearchFilters>, clear = false) =>
      set((state: SearchProps) => ({
        ...state,
        appliedFilters: {
          ...(clear ? DEFAULT_SEARCH_FILTERS : state.appliedFilters),
          ...newFilters,
        },
        filtersAppliedAt: Date.now(),
      })),
    resetFilters: () =>
      set((state: SearchProps) => ({
        ...state,
        appliedFilters: DEFAULT_SEARCH_FILTERS,
      })),
    selectCollection: (selectedCollection: SearchCollectionConfig | null) =>
      set((state: SearchProps) => ({
        ...state,
        selectedCollection,
        isCollectionChanging: true,
      })),
    setIsCollectionChanging: (isCollectionChanging: boolean) =>
      set((state: SearchProps) => ({
        ...state,
        isCollectionChanging,
      })),
    setCauses: (causes: ActivityCategory[]) =>
      set((state: SearchProps) => ({ ...state, causes })),
    openFilterModal: () =>
      set((state: SearchProps) => ({ ...state, isFilterModalOpen: true })),
    closeFilterModal: () =>
      set((state: SearchProps) => ({ ...state, isFilterModalOpen: false })),
    clear: () => set(() => SEARCH_INITIAL_STATE),
  }));
};
const useSearchStore: SearchStore = createSearchStore();

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useSearchStore);
}

export function useSearch<T>(selector: (state: SearchState) => T): T {
  return useStore(useSearchStore, selector, shallow);
}
