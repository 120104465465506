import { Box, Hidden, Text, useBreakpointValue, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import CalendarInline from '~/components/CalendarInline';
import { RootPortal } from '~/components/RootPortal';
import MobileTrayDrawer from '~/components/TrayDrawer';
import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import ActivityLocationSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch';
import ActivityTeamSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch';
import ActivityDateSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityDateSelector';
import ActivityLocationSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector';
import TeamSelectorNavButton from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/TeamSelectorNavButton';

import { useTeamInviteApplicationPanelcontroller } from './controller';
import { TeamInviteApplicationPanelProps } from './types';
export const TeamInviteApplicationPanel: React.FC<
  TeamInviteApplicationPanelProps
> = (props) => {
  const { onGoBackPress } = props;

  const {
    handleApplication,
    headerStyle,
    filters,
    isFromHome,
    activityType,
    blockedDates,
    availableTeams,
    availableDates,
    availableLocations,
    handleNewDate,
    handleNewLocation,
    selectedActivity,
    showCalendarDrawer,
    showLocationDrawer,
    showTeamDrawer,
    closeMobileCalendarDrawer,
    closeMobileLocationDrawer,
    closeMobileTeamDrawer,
    openMobileCalendarDrawer,
    openMobileLocationDrawer,
    handleNewTeam,
    handleCreateNewTeam,
    handleFindOpportunities,
    selectedTeam,
    spotsAvailable,
    isSubmitting,
    modalRef,
    handleViewEvent,
    isAuthenticated,
  } = useTeamInviteApplicationPanelcontroller();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <Modal
        headerText="You’re in!"
        modalContentMaxW="700"
        rightBtnText="Find more opportunities"
        rightBtnAction={handleFindOpportunities}
        leftBtnText="View event"
        leftBtnAction={handleViewEvent}
        ref={modalRef}
        onClose={handleViewEvent}
      >
        <Text mt={{ md: 5 }} color="gray.500" fontSize="sm">
          You&apos;ve successfully joined the team, and your spot in{' '}
          {selectedActivity?.activityDefinitionSubDocument?.title || ''} is
          confirmed!
        </Text>
      </Modal>
      <ActivityPanel
        isLoaded={!!selectedActivity}
        title={'You are invited to join 1 team'}
        titleColor={headerStyle!.fontColor}
        headerBackground={headerStyle!.backgroundColor}
      >
        <VStack
          borderWidth={1}
          borderRadius={'12px'}
          borderColor={colors.gray[200]}
          overflow={'hidden'}
        >
          <ActivityLocationSelector
            isFromHome={isFromHome}
            availableLocations={availableLocations}
            selectedLocation={filters.location}
            onLocationChange={handleNewLocation}
            onOpen={isMobile ? openMobileLocationDrawer : undefined}
          />

          <ActivityDateSelector
            availableSpotsCount={spotsAvailable}
            showAvailableSpots={activityType !== ActivitySemanticType.TeamEvent}
            blockedDates={blockedDates}
            availableDates={availableDates}
            selectedDate={filters.startDate}
            onDateChange={handleNewDate}
            onOpen={isMobile ? openMobileCalendarDrawer : undefined}
          />
        </VStack>

        <Button
          onPress={handleApplication}
          _text={{ fontWeight: 500, fontSize: 'md' }}
          mt={5}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Join the team for this event
        </Button>
        {isAuthenticated ? (
          <Button
            mt={2}
            backgroundColor="gray.100"
            _text={{ color: colors.singletons.black, fontWeight: 500 }}
            leftIconName="plus"
            leftIconColor={colors.singletons.black}
            leftIconSize={16}
            onPress={onGoBackPress}
          >
            Add team
          </Button>
        ) : null}
      </ActivityPanel>

      <RootPortal>
        <Hidden from="lg">
          <>
            <MobileTrayDrawer
              title=""
              isOpen={showCalendarDrawer}
              onClose={closeMobileCalendarDrawer}
              size={462}
            >
              <VStack alignItems={'center'}>
                <Box w={'full'} maxW={'500px'}>
                  <CalendarInline
                    date={filters.startDate}
                    onChange={handleNewDate}
                    blockedDates={blockedDates}
                    dates={availableDates}
                    key={selectedActivity?._id}
                  />
                </Box>
              </VStack>
            </MobileTrayDrawer>

            <MobileTrayDrawer
              title=""
              isOpen={showLocationDrawer}
              onClose={closeMobileLocationDrawer}
              size={462}
            >
              <VStack alignItems={'center'}>
                <Box w={'full'} maxW={'768px'}>
                  <ActivityLocationSearch
                    locations={availableLocations}
                    selectedLocation={filters.location}
                    onLocationChange={handleNewLocation}
                    key={selectedActivity?._id}
                  />
                </Box>
              </VStack>
            </MobileTrayDrawer>

            {activityType === ActivitySemanticType.TeamEvent && (
              <MobileTrayDrawer
                title=""
                isOpen={showTeamDrawer}
                onClose={closeMobileTeamDrawer}
                size={462}
              >
                <VStack alignItems={'center'} overflowY={'auto'} space={2}>
                  <Box flex={1} w={'full'} maxW="768px">
                    <ActivityTeamSearch
                      key={selectedTeam?.name}
                      teams={availableTeams}
                      selectedTeam={selectedTeam}
                      onTeamChange={handleNewTeam}
                    />
                  </Box>
                  <Box w="full" maxW="768px">
                    <TeamSelectorNavButton onPress={handleCreateNewTeam} />
                  </Box>
                </VStack>
              </MobileTrayDrawer>
            )}
          </>
        </Hidden>
      </RootPortal>
    </>
  );
};

export default TeamInviteApplicationPanel;
