import { PresenceTransition, Skeleton } from 'native-base';
import SwipeableViews from 'react-swipeable-views';

import ActivityApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication';
import { TeamEventApplicationPanel } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/TeamEventApplicationPanel';
import TeamInviteApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/TeamInviteApplicationPanel';
import { useActivityInteractiveTabsController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityInteractiveTabs/controller';
import ActivityLogPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel';

/*
  -- ActivityInteractiveTabs --

  This is the desktop aside content for managing application and activity logs.
  You can switch between application and log tabs.
*/

export const ActivityInteractiveTabs = () => {
  const {
    tabIndex,
    gotToTab,
    activities,
    selectedActivity,
    appliedActivities,
    hasApplicationsToLog,
    hasActivitiesToApply,
    teams,
    teamInvite,
    teamAppliedActivities,
  } = useActivityInteractiveTabsController();

  return (
    <PresenceTransition
      visible
      style={{ width: '100%' }}
      initial={{ opacity: 0, translateY: 0.75 }}
      animate={{ opacity: 1, translateY: 1, transition: { duration: 300 } }}
    >
      <SwipeableViews index={tabIndex}>
        <Skeleton isLoaded={hasActivitiesToApply} h={'340px'}>
          {hasActivitiesToApply && (
            <ActivityApplicationPanel
              key={selectedActivity?._id}
              showGoBackButton={hasApplicationsToLog || !!teams?.length}
              onGoBackPress={() =>
                gotToTab(teams?.length && !hasApplicationsToLog ? 2 : 1)
              }
              teams={teams || []}
            />
          )}
        </Skeleton>
        <Skeleton isLoaded={hasApplicationsToLog} h={'340px'}>
          {hasApplicationsToLog && (
            <ActivityLogPanel
              activitiesTotal={activities.length}
              appliedActivities={appliedActivities}
              key={appliedActivities.length}
              onGoBackPress={() => gotToTab(0)}
              teams={teams || []}
              teamAppliedActivities={teamAppliedActivities}
            />
          )}
        </Skeleton>
        <Skeleton isLoaded={!!teams}>
          <TeamEventApplicationPanel
            teams={teams || []}
            onGoBackPress={() => gotToTab(0)}
          />
        </Skeleton>
        <Skeleton isLoaded={!!teamInvite}>
          <TeamInviteApplicationPanel onGoBackPress={() => gotToTab(0)} />
        </Skeleton>
      </SwipeableViews>
    </PresenceTransition>
  );
};

export default ActivityInteractiveTabs;
